<template>
  <router-view name="home" v-slot="{ Component }">
    <Transition name="fade">
      <component :is="Component" />
    </Transition>
  </router-view>
  <router-view v-slot="{ Component }">
    <Transition name="fade">
      <div class="header" v-if="Component">
        <div class="logo">
          <a href="/"><SvgIcon name="logo" /></a>
        </div>
        <div class="menu">
          <router-link :to="{ name: 'extrusion-dies' }">
            <SvgIcon name="extrusion-dies" />
            <div class="title">Extrusion Dies</div>
          </router-link>
          <router-link :to="{ name: 'feedblocks' }">
            <SvgIcon name="feedblocks" />
            <div class="title">Feedblocks</div>
          </router-link>
          <router-link :to="{ name: 'ancillary' }">
            <SvgIcon name="ancillary" />
            <div class="title">Ancillary</div>
          </router-link>
          <router-link :to="{ name: 'company' }">
            <SvgIcon name="company" />
            <div class="title">Company</div>
          </router-link>
        </div>
      </div>
    </Transition>
    <Transition name="fade">
      <component class="view" :is="Component" />
    </Transition>
  </router-view>
  <Transition name="snackbar">
    <div v-if="updateExists" class="snackbar">
      <div class="snackbar__content">A new version is available</div>
      <div class="snackbar__button">
        <a @click.prevent="refreshApp"><span>Refresh</span></a>
      </div>
    </div>
  </Transition>
</template>

<script>
import update from '@/mixins/update';

export default {
  name: 'App',
  mixins: [update],
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.3em;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.15em;
  background-color: rgba(black, 0.5);
}
body {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: black;
  user-select: none;
  position: fixed;
}
.fade-enter-active,
.fade-leave-active {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  @include transition(all, 0.3s);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
p {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  line-height: 1.2;
}
h2 {
  font-weight: normal;
  color: white;
  margin: 0;
}
svg {
  width: auto;
  height: 100%;
}
a {
  cursor: pointer;
  text-decoration: none;
  @include transition;
}
.snackbar {
  position: absolute;
  bottom: 1.5%;
  left: 50%;
  transform: translateX(-50%);
  background: $bg;
  color: white;
  display: flex;
  font-size: 70%;
  box-shadow: 0 0.186em 0.31em -0.06em rgba(black, 20%),
    0 0.37em 0.62em 0 rgba(black, 14%), 0 0.062em 1.117em 0 rgba(black, 12%);
  border-radius: 0.5em;
  &.snackbar-enter-active,
  &.snackbar-leave-active {
    @include transition(all, 0.4s);
  }
  &.snackbar-enter-from,
  &.snackbar-leave-to {
    opacity: 0;
    transform: translate(-50%, 10%);
  }
  &__content {
    flex-grow: 1;
    padding: 1em 0 0.95em 1.25em;
    white-space: nowrap;
    line-height: 1;
  }
  &__button {
    display: flex;
    align-items: stretch;
    justify-content: center;
    a {
      padding: 0 1.25em;
      color: $accent;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 95%;
      line-height: 1;
    }
  }
}
.view {
  display: flex;
  flex-direction: column;
  padding: 4%;
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: center;
  &.products .page-title {
    flex-direction: row;
    align-items: flex-end;
  }
  &.fade-enter-active,
  &.fade-leave-active {
    top: unset;
    bottom: 0;
    height: 86.12%;
  }
}
sup {
  vertical-align: top;
}
#app {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  @include convert('height', $minWidth, 'vw');
  width: 100%;
  position: relative;
  font-size: 1.388888889vw;
  overflow: hidden;
  background-image: url('@/assets/images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint('outside') {
    height: 100vh;
    @include convert('width', $maxWidth, 'vh');
    @include convert('font-size', 20, 'vh');
  }
  @include breakpoint('inside') {
    height: 100vh;
    @include convert('font-size', 20, 'vh');
  }
}
.header {
  background: $bg;
  display: flex;
  flex-direction: row;
  padding: 0 2.5%;
  height: 13.88%;
  > .logo {
    height: 100%;
    display: flex;
    align-items: center;
    > a {
      height: 41.33333333%;
      .icon {
        height: 100%;
        fill: white;
      }
    }
  }
  .menu {
    display: flex;
    width: 45%;
    height: 100%;
    margin-left: auto;
    justify-content: space-evenly;
    @include breakpoint('both') {
      width: 40%;
    }
    > a {
      width: 25%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 6.75% 0;
      box-sizing: border-box;
      font-size: 75%;
      text-align: center;
      @include breakpoint('both') {
        padding-top: 5.5%;
        padding-bottom: 5.5%;
      }
      .icon {
        fill: rgba($icon, 0.5);
        height: 70%;
        width: 100%;
        margin-bottom: 0.75em;
        @include transition(fill);
        @include breakpoint('both') {
          height: 60%;
        }
      }
      .title {
        color: rgba(white, 0.8);
        @include transition(color);
      }
      &.router-link-active {
        background-color: rgba(white, 0.1);
        .icon {
          fill: $accent;
        }
        .title {
          color: white;
        }
      }
    }
  }
}
.page-title {
  color: white;
  margin-top: 0;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 100%;
  h2 {
    margin: 0;
    font-size: 275%;
  }
  p {
    font-size: 100%;
    margin-top: 1.5%;
    margin-bottom: 0;
  }
  .breadcrumb {
    padding: 1.25% 2.5%;
    background: $bg;
    border-radius: 2em;
    text-transform: uppercase;
    font-size: 67%;
    color: $body;
    margin-bottom: 0.5%;
    display: inline-flex;
    @include breakpoint('both') {
      padding: 1% 2%;
      margin-bottom: 1%;
    }
  }
  .navigation {
    display: flex;
    fill: white;
    width: 6%;
    margin-left: auto;
    justify-content: space-around;
    column-gap: 12%;
    a {
      @include transition(opacity, 0.3s);
      width: 50%;
      display: block;
      opacity: 1;
      &.next {
        padding-left: 20%;
      }
      &.previous {
        padding-right: 20%;
      }
      &.disabled {
        opacity: 0.25;
      }
    }
  }
}
.company-detail {
  padding-top: 2%;
  .page-title {
    align-items: flex-end;
    margin-bottom: 2%;
    flex-direction: row;
    > div:first-child {
      width: 94%;
    }
    .breadcrumb {
      padding: 0.5% 1%;
      margin-bottom: 0.5%;
    }
  }
  .company-detail__tabs {
    flex-grow: 1;
    display: flex;
    .content {
      width: 77%;
      margin-left: auto;
      display: flex;
    }
  }
}
</style>
