<template>
  <div class="company-detail history">
    <div class="page-title">
      <div>
        <router-link
          :to="{
            name: 'company',
          }"
          class="breadcrumb"
        >
          Company
        </router-link>
        <h2>History</h2>
      </div>
      <div class="navigation" v-if="pages.length > 0">
        <a
          class="previous"
          :class="{ disabled: activeIndex === 0 }"
          @click.prevent="previous"
        >
          <SvgIcon name="chevron-left" />
        </a>
        <a
          class="next"
          :class="{ disabled: activeIndex === pages.length - 1 }"
          @click.prevent="next"
        >
          <SvgIcon name="chevron-right" />
        </a>
      </div>
    </div>
    <div class="company-detail__tabs">
      <CompanyTabs />
      <div
        class="content"
        v-touch:swipe.right="previous"
        v-touch:swipe.left="next"
      >
        <TransitionGroup name="fade">
          <div
            class="history-cards"
            v-show="activeIndex === index"
            v-for="(page, index) in pages"
            :key="index"
          >
            <div class="card" v-for="item in page" :key="item">
              <h2>{{ item.year }}</h2>
              <p v-html="escape(item.text)" />
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTabs from '@/components/CompanyTabs';
import history from '@/assets/data/history';

export default {
  name: 'CompanyHistoryView',
  components: {
    CompanyTabs,
  },
  data() {
    return {
      pages: this.chunk(history, 8),
      activeIndex: 0,
    };
  },
  methods: {
    next() {
      let index = this.activeIndex + 1;

      if (this.pages[index]) {
        this.activeIndex = index;
      }
    },
    previous() {
      let index = this.activeIndex - 1;

      if (this.pages[index]) {
        this.activeIndex = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  .history-cards {
    display: grid;
    column-gap: 2.5%;
    row-gap: 5%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    height: 100%;
    .card {
      background: $bg;
      padding: 10%;
      h2 {
        font-size: 200%;
        margin-bottom: 5%;
      }
      p {
        color: $body;
        margin: 0;
        font-weight: bold;
        font-size: 90%;
        line-height: 1.4;
      }
    }
  }
}
</style>
