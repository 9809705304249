<template>
  <div v-if="paths" class="icon" :class="name">
    <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox ? viewBox : null">
      <path v-for="path in paths" :key="path" :d="path" />
    </svg>
  </div>
</template>

<script>
import icons from '@/assets/data/icons';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewBox: null,
      paths: [],
    };
  },
  mounted() {
    const icon = icons.find((icon) => icon.name === this.name);

    if (icon) {
      this.viewBox = icon.viewBox;
      this.paths = icon.paths;
    }
  },
};
</script>
