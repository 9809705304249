<template>
  <div class="home">
    <video ref="video" playsinline autoplay muted loop @click="show">
      <source v-if="videoUrl" :src="videoUrl" type="video/mp4" />
    </video>
    <Transition name="fade">
      <div class="overlay" v-show="showOverlay" @click="hide">
        <div class="overlay__inner">
          <div class="logo-wrapper">
            <SvgIcon name="logo" />
          </div>
          <div class="menu">
            <router-link :to="{ name: 'extrusion-dies' }">
              <SvgIcon name="extrusion-dies" />
              <div class="title">Extrusion Dies</div>
            </router-link>
            <router-link :to="{ name: 'feedblocks' }">
              <SvgIcon name="feedblocks" />
              <div class="title">Feedblocks</div>
            </router-link>
            <router-link :to="{ name: 'ancillary' }">
              <SvgIcon name="ancillary" />
              <div class="title">Ancillary</div>
            </router-link>
            <router-link :to="{ name: 'company' }">
              <SvgIcon name="company" />
              <div class="title">Company</div>
            </router-link>
          </div>
          <div class="close">
            <SvgIcon name="chevron-down" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      showOverlay: false,
      videoUrl: null,
      timer: null,
    };
  },
  async mounted() {
    this.videoUrl = await this.createObjectURL(
      require(`@/assets/videos/${
        window.location.host.split('.')[0] === 'kiosk-alt'
          ? 'home-alt.mp4'
          : 'home.mp4'
      }`)
    );
  },
  methods: {
    show() {
      this.showOverlay = true;

      if (typeof this.timer === 'number') {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => (this.showOverlay = false), 10000);
    },
    hide() {
      clearTimeout(this.timer);

      this.showOverlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &,
  video,
  .overlay,
  .overlay__inner {
    height: 100%;
    width: 100%;
  }
}
.home {
  background: black;
}
.overlay {
  background: rgba($bg, 0.4);
  backdrop-filter: blur(40px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include transition(all, 0.4s);
  &.fade-enter-active,
  &.fade-leave-active {
    .overlay__inner {
      @include transition(all, 0.4s);
    }
  }
  &.fade-enter-from,
  &.fade-leave-to {
    backdrop-filter: blur(0);
    .overlay__inner {
      transform: translateY(1%);
    }
  }
  .logo-wrapper {
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      height: 22%;
      fill: white;
      width: 100%;
      text-align: center;
    }
  }
  .menu {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
    display: flex;
    gap: 0 2%;
    height: 36%;
    > a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 125%;
      background: $bg;
      .icon {
        height: 23%;
        fill: rgba($icon, 0.5);
        margin-bottom: 0.75em;
        width: 100%;
        text-align: center;
        @include transition(fill);
      }
      .title {
        color: rgba(white, 0.8);
        @include transition(color);
      }
    }
  }
  .close {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 3%;
    display: flex;
    justify-content: center;
    padding: 3%;
    margin-bottom: 3%;
    cursor: pointer;
    .icon {
      fill: white;
      height: 100%;
      svg {
        vertical-align: top;
      }
    }
  }
}
</style>
