<template>
  <div class="company-detail awards">
    <div class="page-title">
      <div>
        <router-link
          :to="{
            name: 'company',
          }"
          class="breadcrumb"
        >
          Company
        </router-link>
        <h2>Awards</h2>
      </div>
    </div>
    <div class="company-detail__tabs">
      <CompanyTabs />
      <div class="content">
        <div class="award" v-for="award in awards" :key="award">
          <SvgIcon name="trophy-circle" />
          <div>
            <h2 v-text="award.title" />
            <p v-text="award.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTabs from '@/components/CompanyTabs';
import awards from '@/assets/data/awards';

export default {
  name: 'CompanyAwardsView',
  components: {
    CompanyTabs,
  },
  data() {
    return {
      awards,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3%;
}
.award {
  width: 48.5%;
  background: $bg;
  display: flex;
  align-items: center;
  padding: 1.5% 2%;
  box-sizing: border-box;
  gap: 3%;
  .icon {
    fill: $body;
    width: 15%;
  }
  > div {
    width: 85%;
  }
  h2 {
    font-size: 100%;
    margin-bottom: 1%;
  }
  p {
    color: $body;
    margin: 0;
    font-size: 75%;
  }
}
</style>
