<template>
  <div class="company">
    <div class="page-title">
      <div>
        <h2>Company</h2>
        <p>Superior organization, technology, value and efficiency.</p>
      </div>
    </div>
    <div class="pages">
      <router-link
        :to="{
          name: 'company-history',
        }"
        >History</router-link
      >
      <router-link
        :to="{
          name: 'company-locations',
        }"
        >Locations</router-link
      >
      <router-link
        :to="{
          name: 'company-lab',
        }"
        >Lab</router-link
      >
      <router-link
        :to="{
          name: 'company-awards',
        }"
        >Awards</router-link
      >
      <router-link
        :to="{
          name: 'company-faq',
        }"
        >FAQ</router-link
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pages {
  column-gap: 1.5%;
  row-gap: 10%;
  grid-row-end: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  > a {
    display: block;
    padding: 8%;
    font-size: 160%;
    background: $bg;
    color: white;
  }
}
</style>
