<template>
  <Transition name="fade">
    <div class="media-player">
      <div class="media-player__inner" @click.self="close">
        <h3 v-if="caption" v-html="escape(caption)" />
        <div class="media video" v-if="videoUrl" v-html="videoHtml" />
        <div class="media image" v-if="image">
          <img
            v-touch:swipe.right="swipeRight"
            v-touch:swipe.left="swipeLeft"
            :src="require(`@/assets/${this.image}`)"
            alt=""
          />
        </div>
        <div class="navigation">
          <a v-if="!video" @click.prevent="$emit('previous')" class="previous">
            <SvgIcon name="chevron-left" />
          </a>
          <a @click.prevent="close" class="home">
            <SvgIcon name="grid" />
          </a>
          <a v-if="!video" @click.prevent="$emit('next')" class="next">
            <SvgIcon name="chevron-right" />
          </a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'MediaPlayer',
  emits: ['next', 'previous', 'close'],
  props: ['image', 'caption', 'video'],
  data() {
    return {
      videoUrl: null,
    };
  },
  computed: {
    videoHtml() {
      return `<video id="video" controls disablePictureInPicture controlsList="nodownload" muted autoplay loop>
            <source src="${this.videoUrl}" type="video/mp4" />
          </video>`;
    },
  },
  methods: {
    close() {
      const video = document.getElementById('video');

      if (video) {
        video.pause();
      }

      this.$emit('close');
    },
    swipeRight() {
      this.$emit('previous');
    },
    swipeLeft() {
      this.$emit('next');
    },
  },
  watch: {
    video: {
      async handler(newValue) {
        this.videoUrl = newValue
          ? await this.createObjectURL(require(`@/assets/${newValue}`))
          : null;
      },
    },
  },
};
</script>

<style lang="scss">
.media-player {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($bg, 0.4);
  backdrop-filter: blur(40px);
  top: 0;
  left: 0;
  &.fade-enter-active,
  &.fade-leave-active {
    .media-player__inner {
      @include transition(all, 0.4s);
    }
  }
  &.fade-enter-from,
  &.fade-leave-to {
    backdrop-filter: blur(0);
    .media-player__inner {
      transform: translateY(1%);
    }
  }
  .media-player__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .media {
      line-height: 0;
      @include convert(width, 1360, vw);
      @include breakpoint('both') {
        @include convert(width, 1024, vh);
      }
      &.image img {
        width: 100%;
      }
      &.video {
        @include convert(width, 1280, vw);
        @include convert(height, 960, vw);
        @include breakpoint('both') {
          @include convert(width, 960, vh);
          @include convert(height, 720, vh);
        }
        video {
          width: 100%;
          height: 100%;
          background: black;
        }
      }
    }
    .navigation {
      display: flex;
      position: absolute;
      bottom: 5%;
      a {
        display: block;
        background: $bg;
        @include convert(height, 120, vw);
        @include convert(width, 120, vw);
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint('both') {
          @include convert(width, 90, vh);
          @include convert(height, 90, vh);
        }
        .icon {
          fill: white;
          height: 34.55555556%;
        }
      }
    }
    h3 {
      color: white;
      font-weight: normal;
      font-size: 130%;
      margin: 0;
      position: absolute;
      top: 12%;
    }
  }
}
</style>
