export default [
  {
    year: 1975,
    text: "Peter Cloeren Senior develops the industry's first variable coextrusion feedblock.",
  },
  {
    year: 1979,
    text: 'Feedblock and related coextrusion patents granted to Peter Cloeren Sr.',
  },
  {
    year: 1980,
    text: 'Peter Cloeren Sr. and Peter F. Cloeren, Jr. formally co-found The Cloeren Company, now known as Cloeren Incorporated.',
  },
  {
    year: 1983,
    text: 'Cloeren develops the multi-plane Feedblock for 4 or more layers. This innovation was quickly adopted to become the industry standard.',
  },
  {
    year: 1984,
    text: 'First Cloeren manufacturing plant established in Orange, Texas.',
  },
  {
    year: 1990,
    text: 'Cloeren enters the extrusion die business with the introduction of its Epoch™ Two-Stage Preland Die.',
  },
  {
    year: 1992,
    text: 'Cloeren commercializes the first Flexible Lip and Edge Bead Reduction (EBR™) Die for extrusion coating and laminating.',
  },
  {
    year: 1993,
    text: 'Patent granted to Cloeren for its Epoch™ Two-Stage Preland Die.',
  },
  {
    year: 1993,
    text: 'Cloeren introduces the first Internally Deckled System (IDS™) for cast film and thin sheet applications.',
  },
  {
    year: 1994,
    text: 'Cloeren expands internationally through the opening of Cloeren Japan Ltd. and Cloeren Europe.',
  },
  {
    year: 1997,
    text: 'Cloeren becomes the largest die manufacturer in the world with the purchase of Production Components in Eau Claire, Wisconsin.',
  },
  {
    year: 1998,
    text: 'Cloeren establishes its third manufacturing facility in Orange, Texas.',
  },
  {
    year: 1999,
    text: 'Cloeren establishes sales and service office in Southeast Asia.',
  },
  {
    year: 2001,
    text: 'Cloeren expands and relocates headquarters to downtown Orange, Texas.',
  },
  {
    year: 2002,
    text: 'Cloeren receives 25th U.S. Patent for Polymer Processing Equipment and Design.',
  },
  {
    year: 2002,
    text: "Cloeren introduces world's first commercial NanoLayer™ Feedblock for barrier coextrusion.",
  },
  {
    year: 2003,
    text: "Cloeren opens office in Nagoya; it's second in Japan.",
  },
  {
    year: 2006,
    text: 'Cloeren opens a fourth manufacturing facility in Marshall, Texas.',
  },
  {
    year: 2009,
    text: 'Cloeren opens sales office in Beijing.',
  },
  {
    year: 2010,
    text: 'Cloeren establishes service in Shanghai.',
  },
  {
    year: 2011,
    text: 'Cloeren relocates its Asia Pacific office to Kuala Lumpur.',
  },
  {
    year: 2012,
    text: 'Cloeren expands their sales in Europe by opening another office in Germany.',
  },
  {
    year: 2012,
    text: 'Cloeren patents the moebius-manifold™ Die.',
  },
  {
    year: 2013,
    text: 'Cloeren expands its manufacturing facilities in Orange, Texas.',
  },
  {
    year: 2015,
    text: 'U.S. patent issued for the moebius-manifold™ Die. International patents are still pending.',
  },
  {
    year: 2016,
    text: 'Cloeren has strengthened its stake in the European market by increasing its investment in EMO GesmbH, an extrusion die maker in Micheldorf, Austria.',
  },
  {
    year: 2017,
    text: 'Cloeren purchases the remaining shares of EMO GesmbH, providing us with a manufacturing, service and repair facility in Europe.',
  },
  {
    year: 2019,
    text: 'After three years of research and development, Cloeren commercially launches the revolutionary Reƒlex Die.',
  },
];
