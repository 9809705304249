<template>
  <div class="tabs">
    <router-link
      :to="{
        name: 'company-history',
      }"
      ><SvgIcon name="chevrons-right" />
      <div>History</div></router-link
    >
    <router-link
      :to="{
        name: 'company-locations',
      }"
      ><SvgIcon name="chevrons-right" />
      <div>Locations</div></router-link
    >
    <router-link
      :to="{
        name: 'company-lab',
      }"
      ><SvgIcon name="chevrons-right" />
      <div>Lab</div></router-link
    >
    <router-link
      :to="{
        name: 'company-awards',
      }"
      ><SvgIcon name="chevrons-right" />
      <div>Awards</div></router-link
    >
    <router-link
      :to="{
        name: 'company-faq',
      }"
      ><SvgIcon name="chevrons-right" />
      <div>FAQ</div></router-link
    >
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  background: $bg;
  display: flex;
  flex-direction: column;
  width: 21%;
  align-self: start;
  padding: 1%;
  box-sizing: border-box;
  > a {
    padding: 5%;
    font-size: 130%;
    color: $body;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.router-link-active {
      color: white;
      .icon {
        opacity: 1;
        fill: white;
      }
    }
    .icon {
      width: 5%;
      margin-right: 5%;
      fill: $body;
      opacity: 0;
    }
  }
}
</style>
