export default [
  {
    title: 'Deckles',
    summary:
      'Customized deckle systems control the extruded product width, reduce edge trim and even create monolithic edges on coextruded products.',
    overview: `<b>Internal Deckle System (IDS™)</b>
    The IDS™ features a solid manifold plug and blade that eliminates excessive polymer stagnation by filling the deckled portion of the die cavity with solid deckle components. The die-to-roll position is not affected by the IDS™ system because the deckle is internal to the die cavity and does not protrude beyond the die lips.
    
    <b>Encapsulating Deckle System (EDS™)</b>
    This product features the same benefits as the IDS™, while also providing the ability to produce film and sheet with encapsulated edge portions. This allows for the elimination of expensive material in the edge trim.
    
    <b>Fixed-width Internal Deckles</b>
    These deckles provide a cost-effective solution for processors who do not need an infinitely adjustable range of the IDS™ System. The deckle inserts are easily placed into the ends of the die flow channel to allow custom product widths without excessive edge trim.
    
    <b>Power Wedge™ Deckle System</b>
    This system is an adjustable, wedge sealing, external deckle, suitable for the sheet extrusion process. The system can be fully retracted to the ends of the die. This facilitates lip removal and enhances die cleaning and maintenance.`,
    slug: 'deckles',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*deckles.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'EBR™',
    summary:
      'The EBR™ system will pay for itself in a matter of months in material savings alone, not to mention the noticeable increase in productivity.',
    overview: `Cloeren's patented EBR™ (Edge Bead Reduction) is an internally deckled die consisting of independently adjustable components for reduction of edge-bead and over-coat in the extrusion coating and lamination industries. Consistent with its innovative history, Cloeren was the first to produce these features in a hinged lip die, and to commercialize full cavity adjustable internal deckles.

    <b>Adjustable components</b>
    The EBR™ Die, used in extrusion coating and laminating applications, consists of three independently adjustable components: a patented full-length solid deckle plug, a solid deckle blade, and a profiled deckle rod. This system affords the processor the ability to reduce or eliminate the extruded edge bead and edge trim in extrusion coating and laminating processes. It also allows operators to change the width of the coating on line. 
    
    <b>Technology that pays for itself</b>
    The EBR™ system will pay for itself in a matter of months in material savings alone, not to mention the noticeable increase in productivity.`,
    slug: 'ebr',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*ebr.*\.jpg$/)
      .keys(),
    videos: [
      {
        title: 'EBR™ Die',
        thumbnail: 'ebr-001-thumbnail.jpg',
        filename: 'ebr-001.mp4',
      },
    ],
  },
  {
    title: 'moebius-manifold™',
    summary:
      'Provides a revolutionary solution that reduces residence time and clamshell deflection while increasing the processing window.',
    overview: `Cloeren's newest innovation in die design, the patent-pending moebius-manifold™ Die, provides a revolutionary solution that reduces residence time and clamshell deflection while increasing the processing window.

    The moebius-manifold™ Die incorporates a varying manifold transition angle that provides a diminishing cross-sectional area manifold while maintaining Cloeren's trademark straight-backline manifold design. The varying lead angle further enhances streamlining at the manifold ends where the flow rates are the lowest. This provides the latest enhancement for heat-sensitive and residence-time-sensitive polymers, such as PVC and fluoropolymers.
    
    Uniquely, the moebius-manifold™ is suitable for Cloeren's full cavity IDS™ Internal Deckle System.`,
    slug: 'moebius-manifold',
    root: 'extrusion-dies',
    images: require
      .context(
        '../images/galleries',
        false,
        /^\.\/[^_]*moebius-manifold.*\.jpg$/
      )
      .keys(),
    videos: [
      {
        title: 'moebius-manifold™ Die',
        thumbnail: 'moebius-manifold-001-thumbnail.jpg',
        filename: 'moebius-manifold-001.mp4',
      },
    ],
  },
  {
    title: 'Fundamentals',
    summary:
      'Features a two stage, non-linear preland that is optimized for uniform cross-direction flow distribution.',
    overview: `Proper design of a Die is essential for successful coextrusion. The Cloeren Epoch™ Die Series features unique manifold geometries and a patented two-stage preland. This exclusive combination, together with custom engineering, provides unparalleled mass flow distribution across the die width, which increases process yield and quality of the finished product. The patented Epoch™ Die was the first commercially established die that addressed non-uniform die clamshelling. Both the Epoch™ III and Epoch™ V feature a two stage, non-linear preland that is optimized for uniform cross-direction flow distribution.

      <b>Epoch™ III</b>
      Cloeren's coextrusion die designs feature low energy dissipation manifolds beneficial to individual layer uniformities across the die width, complementary to the feedblock coextrusion process.
      
      <b>Epoch™ V</b>
      Our custom design features a streamlined teardrop cross-section manifold suitable for mono-layer extrusions.`,
    slug: 'fundamentals',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*fundamentals.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'Reƒlex™',
    summary:
      'Another industry first by Cloeren, the Reƒlex™ platform further leads the way for smarter extrusion systems requiring less hands-on intervention for operation.',
    overview: `The Reƒlex™ system provides for several unique, industry leading features including, (i) unparalleled response times, (ii) greater lip displacement, (iii) elimination of operator adjustment with a factory preset lip gap, (iv) programmed change of the nominal lip gap without human intervention, and (v) lip sealing of internal deckles without operator intervention, all with compatible control systems.
    
    Another industry first by Cloeren, the Reƒlex™ platform further leads the way for smarter extrusion systems requiring less hands-on intervention for operation.`,
    slug: 'reflex',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*reflex.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'Multi-Manifold',
    summary:
      'The preferred die for applications demanding the ability to combine materials with widely dissimilar viscoelastic melt characteristics.',
    overview: `The Epoch™ IV Multi-Manifold Die features all the attributes of the Epoch™, and/or moebius manifold families and is the preferred die for applications demanding the ability to combine materials with widely dissimilar viscoelastic melt characteristics.

    Another valuable feature of the Epoch™ IV Multi-Manifold Die is the ability to control the width of individual layers, with individual internal deckles, or Naked Edge™ restriction for oriented film applications. The Epoch™ IV is available from 2 to 5 layers and can also be combined with a Cloeren feedblock to increase the number of layers.`,
    slug: 'multi-manifold',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*multi-manifold.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'Sheet',
    summary:
      'Cloeren offers the most variety, the best quality, and the most advanced die choices available for sheet extrusion.',
    overview: `Because of the broad range of polymers processed and the diversity of widths and thicknesses produced, the sheet industry requires an extensive variety of equipment options. Cloeren offers the most variety, the best quality, and the most advanced die choices available for sheet extrusion. With our extensive polymer knowledge, we can provide a solution for an entire spectrum of sheet applications.

    <b>Creating diverse products</b>
    Cloeren equipment can be designed to make products as diverse as pharmaceutical bags and aerospace films, in both extra wide sheets or very narrow sheets. Our dies can also make optical quality sheets up to 25 mm thick, thin sheets for thermoformed packaging, NanoLayer™ sheets for better flex-crack resistance, and sheets that are over 30 mm thick.
    
    <b>emo™ Die</b>
    Cloeren purchased all remaining shares of the Austrian extrusion die company EMO GmbH in 2017. One of the results of this acquisition is the introduction of a non-linear die design in our product portfolio. The emo™ Die is suitable for monolayer structures.`,
    slug: 'sheet',
    root: 'extrusion-dies',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*sheet.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'FG™',
    summary:
      'We designed the Cloeren Fixed Geometry (FG™) Feedblock for ease of use with basic formulas and applications.',
    overview: `We designed the Cloeren Fixed Geometry (FG™) Feedblock for ease of use with basic formulas and applications.

      <b>Easily removable inserts</b>
      The FG™ Feedblock has fixed geometry flow inserts that can easily be removed. It also features one of Cloeren's industry standards, the Selector Plug™ cartridge, which facilitates changes to layer sequencing.
      
      <b>Customized feedblock design</b>
      Cloeren works with you to determine exactly what materials and layer formulations will be processed before designing and building your feedblock. Cloeren FG™ Feedblocks are available from two to an infinite number of layer configurations.`,
    slug: 'fg',
    root: 'feedblocks',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*fg.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'NanoLayer™',
    summary:
      "Cloeren's NanoLayer™ Feedblock is a breakthrough for extrusion in the industrial and consumer packaging industries.",
    overview: `Developed in 2002, Cloeren's NanoLayer™ Feedblock is a breakthrough for extrusion in the industrial and consumer packaging industries. This innovative process enables customers to develop film applications with a high number of layers.  The NanoLayer™ Feedblock is used to enhance barrier performance, mechanical properties and/or optical properties.

      <b>Multiple applications</b>
      These systems are used in applications such as: High Flex Barrier Film, Optical Film, Stretch Film and High Barrier Food Packing Film.
      
      <b>Award winning designs</b>
      This patented technology was awarded the Gold DuPont Sustainability Award in 2011.`,
    slug: 'nanolayer',
    root: 'feedblocks',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*nanolayer.*\.jpg$/)
      .keys(),
    videos: [
      {
        title: 'NanoLayer™ Feedblock #1',
        thumbnail: 'nanolayer-001-thumbnail.jpg',
        filename: 'nanolayer-001.mp4',
      },
      {
        title: 'NanoLayer™ Feedblock #2',
        thumbnail: 'nanolayer-002-thumbnail.jpg',
        filename: 'nanolayer-002.mp4',
      },
      {
        title: 'NanoLayer™ Feedblock #3',
        thumbnail: 'nanolayer-003-thumbnail.jpg',
        filename: 'nanolayer-003.mp4',
      },
      {
        title: 'NanoLayer™ Feedblock #4',
        thumbnail: 'nanolayer-004-thumbnail.jpg',
        filename: 'nanolayer-004.mp4',
      },
    ],
  },
  {
    title: 'TopHat™',
    summary:
      'Cloeren offers options that increase efficiency, improve ease of use, and minimize downtime during layer sequence changes.',
    overview: `To supplement our award-winning feedblock technologies, Cloeren offers options that increase efficiency, improve ease of use, and minimize downtime during layer sequence changes.

    <b>Selector Plug™ Cartridge</b>
    The Selector Plug™ is an interchangeable cartridge located upstream of the layer forming section of the Feedblock. It enables the operator to quickly change the layer sequence without disassembling the feedblock or melt delivery pipes. By simply exchanging the Selector Plug™ with another one having a different channel routing, the product layer sequence can be altered in less than 30 minutes.
    
    Additional Selector Plugs™, for different layer sequences, can be purchased with the feedblock, or are available as an aftermarket item.
    
    <b>TopHat™</b>
    The patented TopHat™ is an alternative to the Selector Plug™, and is best suited for ultimate streamlining for heat sensitive polymer applications.`,
    slug: 'tophat',
    root: 'feedblocks',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*tophat.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'VG™',
    summary:
      'The mainstay of feedblocks is the versatile Variable Geometry (VG™) Feedblock.',
    overview: `The mainstay of feedblocks is the versatile Variable Geometry (VG™) Feedblock.

    <b>Movable vane</b>
    Cloeren's legacy movable vane design provides for simple, external adjustment of the shear stress. This feature allows the user to compensate for changes in the layer structure to maintain optimum process flow characteristics.
    
    <b>Distribution pins</b>
    This Cloeren innovation provides externally adjustable means for tuning individual layer uniformity when needed, by allowing profiling of a selected flow stream at the point of flow convergence of polymer layers with different viscoelastic characteristics. This feature fine tunes individual layer uniformity for optimum product quality, and enhances product development and process quality.
    
    <b>Reliability and maximum flexibility with on-line process adjustability</b>
    The VG™ Feedblock is available from two layers up to thirteen or more layers. The VG™ Feedblock allows customers to make adjustments to the flow geometry, and to fine-tune shear stress and layer uniformity on line - saving on downtime and reducing the customer's total cost of ownership.`,
    slug: 'vg',
    root: 'feedblocks',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*vg.*\.jpg$/)
      .keys(),
    videos: [
      {
        title: 'VG™ Feedblock',
        thumbnail: 'vg-001-thumbnail.jpg',
        filename: 'vg-001.mp4',
      },
    ],
  },
  {
    title: 'Vacuum Box',
    summary:
      'The Vacuum Box works by evacuating air between the film and the chill roll surface.',
    overview: `The Vacuum Box works by evacuating air between the film and the chill roll surface. By actually drawing the film onto the chill roll surface, it enhances web cooling, facilitates higher line speeds and reduces film neck-in. It also reduces drag at the lip exit. The result: increased productivity. The Vacuum Box is available with single and dual chambers.`,
    slug: 'vacuum-box',
    root: 'ancillary',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*vacuum-box.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'Drool Pans',
    summary:
      'Drool Pans will keep the work area clean and minimizing contamination of the polymer so it can be recycled.',
    overview: `During routine maintenance or for product changeover in the extrusion coating and laminating process, a drool pan will capture the extrudate from the die, keeping the work area clean and minimizing contamination of the polymer so it can be recycled.`,
    slug: 'drool-pans',
    root: 'ancillary',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*drool-pans.*\.jpg$/)
      .keys(),
    videos: [],
  },
  {
    title: 'On-Line Die Splitter',
    summary:
      'The Cloeren on-line die splitter makes die cleaning easier and safer.',
    overview: `The Cloeren on-line die splitter makes die cleaning easier and safer. The on-line die splitter minimizes potential damage to the die during maintenance and cleaning, and helps improve efficiency. Precision-fabricated rails and hardened cams allow the die bodies to be split and cleaned for re-assembly.`,
    slug: 'on-line-die-splitter',
    root: 'ancillary',
    images: require
      .context(
        '../images/galleries',
        false,
        /^\.\/[^_]*on-line-die-splitter.*\.jpg$/
      )
      .keys(),
    videos: [],
  },
  {
    title: 'Cleaning Carts',
    summary:
      'Cloeren offers a complete line of off-line die cleaning carts to facilitate maintenance, transport, storage and preheating of a die.',
    overview: `Cloeren offers a complete line of off-line die cleaning carts to facilitate maintenance, transport, storage and preheating of a die.`,
    slug: 'cleaning-carts',
    root: 'ancillary',
    images: require
      .context('../images/galleries', false, /^\.\/[^_]*cleaning-carts.*\.jpg$/)
      .keys(),
    videos: [],
  },
];
