<template>
  <div class="products extrusion-dies">
    <ProductsGrid
      title="Extrusion Dies"
      description="Cloeren offers the most advanced extrusion die technology available."
      :columns="4"
      :rows="2"
    />
  </div>
</template>

<script>
import ProductsGrid from '@/components/ProductsGrid';

export default {
  name: 'ExtrusionDiesView',
  components: {
    ProductsGrid,
  },
};
</script>

<style lang="scss">
.extrusion-dies.view .products-grid {
  flex-grow: 1;
}
</style>
