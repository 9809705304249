<template>
  <div class="company-detail locations">
    <div class="page-title">
      <div>
        <router-link
          :to="{
            name: 'company',
          }"
          class="breadcrumb"
        >
          Company
        </router-link>
        <h2>Locations</h2>
      </div>
    </div>
    <div class="company-detail__tabs">
      <CompanyTabs />
      <div class="content">
        <div class="image">
          <img :src="require('@/assets/images/locations.jpg')" alt="" />
        </div>
        <div class="info">
          <div>
            <div class="title">
              <SvgIcon name="star" />
              <h2>Headquarters</h2>
            </div>
            <ul>
              <li>Cloeren Headquarters is located in Orange, TX</li>
            </ul>
          </div>
          <div>
            <div class="title">
              <SvgIcon name="triangle" />
              <h2>Manufacturing Facilities</h2>
            </div>
            <div>
              <ul>
                <li>Hwy. 90 Works in Orange, TX</li>
                <li>Hwy. 62 Works in Orange, TX</li>
              </ul>
              <ul style="margin-left: auto">
                <li>Eau Claire Works in Eau Claire, WI</li>
                <li>Marshall Works in Marshall, TX</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTabs from '@/components/CompanyTabs';

export default {
  name: 'CompanyLocationsView',
  components: {
    CompanyTabs,
  },
};
</script>

<style lang="scss" scoped>
.content {
  flex-direction: column;
  .image {
    overflow: hidden;
    margin-bottom: 2.5%;
    line-height: 0;
    flex-grow: 1;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .info {
    display: flex;
    align-items: stretch;
    column-gap: 2.5%;
    > div {
      background: $bg;
      padding: 2.75%;
      width: 100%;
      > div {
        display: flex;
        flex-direction: row;
      }
      .title {
        display: flex;
        align-items: center;
        fill: $accent;
        margin-bottom: 2%;
        .icon {
          width: 4%;
          line-height: 0;
          margin-right: 2%;
        }
      }
      ul {
        color: $body;
        margin: 0;
        font-size: 75%;
        padding-left: 0;
        list-style-type: none;
      }
    }
  }
}
</style>
