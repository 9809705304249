<template>
  <div class="company-detail lab">
    <div class="page-title">
      <div>
        <router-link
          :to="{
            name: 'company',
          }"
          class="breadcrumb"
        >
          Company
        </router-link>
        <h2>Lab</h2>
      </div>
    </div>
    <div class="company-detail__tabs">
      <CompanyTabs />
      <div class="content">
        <div class="lab-card">
          <img :src="require('@/assets/images/lab-001.jpg')" alt="" />
          <div>
            <h2>
              Cloeren is the industry leader in extrusion and coextrusion die
              design
            </h2>
            <p>
              Rheology, which is the Science of Flow, is taken very seriously to
              characterize the flow behavior of polymer melts and solutions that
              will flow in our dies and feedblocks. Precise characterization is
              the foundation of optimal die design for high performance
              extrusion and coextrusion.
            </p>
          </div>
        </div>
        <div class="lab-card">
          <img :src="require('@/assets/images/lab-002.jpg')" alt="" />
          <div>
            <h2>
              Our lab is equipped with state of the art capillary and rotational
              rheometers
            </h2>
            <p>
              These rheometers are used to characterize shear viscosity as a
              function of shear rate and temperature. In addition, important
              characteristics such as flow instability, thermal stability and
              viscoelastic properties can be determined in our lab.
            </p>
          </div>
        </div>
        <div class="lab-card">
          <img :src="require('@/assets/images/lab-003.jpg')" alt="" />
          <div>
            <h2>A brand new optical microscope system</h2>
            <p>
              Which includes a hot stage and a microtome for sample preparation
              is also featured in our lab. The system is used mainly to
              determine layer thickness distributions for coextruded structures,
              gels identification with the hot stage and other defects (poor
              mixing, contaminants, etc…) related to film extrusion.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTabs from '@/components/CompanyTabs';

export default {
  name: 'CompanyLabView',
  components: {
    CompanyTabs,
  },
};
</script>

<style lang="scss" scoped>
.content {
  gap: 2.5%;
}
.lab-card {
  display: flex;
  flex-direction: column;
  width: 32.5%;
  img {
    width: 100%;
  }
  > div {
    height: 100%;
    box-sizing: border-box;
    background: $bg;
    padding: 10%;
    color: $body;
    h2 {
      font-size: 150%;
      margin-bottom: 5%;
      line-height: 1;
    }
    p {
      font-size: 80%;
      line-height: 1.4;
      margin: 0;
    }
  }
}
</style>
