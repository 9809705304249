<template>
  <div class="product-detail" :class="slug">
    <div class="product-detail__content">
      <div class="page-title">
        <router-link
          :to="{
            name: root,
          }"
          class="breadcrumb"
        >
          {{ root.replace(/-/, ' ') }}
        </router-link>
        <h2 v-html="escape(title)" />
      </div>
      <div class="product-detail__tabs">
        <div class="tabs">
          <a
            @click.prevent="activeTab = 'overview'"
            :class="{ active: activeTab === 'overview' }"
          >
            <SvgIcon name="overview" />
          </a>
          <a
            @click.prevent="activeTab = 'images'"
            :class="{ active: activeTab === 'images' }"
          >
            <SvgIcon name="images" />
          </a>
          <a
            @click.prevent="activeTab = 'videos'"
            :class="{ active: activeTab === 'videos' }"
          >
            <SvgIcon name="videos" />
          </a>
          <router-link
            class="previous"
            :to="{
              name: root,
            }"
          >
            <SvgIcon name="chevron-left" />
          </router-link>
        </div>
        <TransitionGroup class="tab-contents" name="fade" tag="div">
          <div
            :key="'overview'"
            class="tab-content overview"
            v-show="activeTab === 'overview'"
          >
            <h3>Overview</h3>
            <div v-if="overview" class="tab-content__inner">
              <div>
                <p v-html="escape(overview)" />
              </div>
            </div>
            <p v-else>No overview found.</p>
          </div>
          <div
            :key="'images'"
            class="tab-content images"
            v-show="activeTab === 'images'"
          >
            <h3>Images</h3>
            <div v-if="images.length > 0" class="tab-content__inner">
              <div>
                <img
                  v-for="(image, index) in images"
                  @click="showMedia(image, index)"
                  :key="image"
                  :src="
                    require(`@/assets/images/galleries/${image.replace(
                      /^\.\//,
                      ''
                    )}`)
                  "
                  alt=""
                />
              </div>
            </div>
            <p v-else>No images found.</p>
          </div>
          <div
            :key="'videos'"
            class="tab-content videos"
            v-show="activeTab === 'videos'"
          >
            <h3>Videos</h3>
            <div v-if="videos.length > 0" class="tab-content__inner">
              <div>
                <a
                  @click.prevent="showMedia(video.filename, index)"
                  class="video"
                  v-for="(video, index) in videos"
                  :key="video"
                >
                  <div class="thumbnail">
                    <img
                      :src="require(`@/assets/videos/${video.thumbnail}`)"
                      alt=""
                    />
                    <SvgIcon name="play-circle" />
                  </div>
                  <h3 v-html="escape(video.title)" />
                </a>
              </div>
            </div>
            <p v-else>No videos found.</p>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <div class="product-detail__image">
      <img :src="require(`@/assets/images/products/${slug}.png`)" alt="" />
    </div>
    <MediaPlayer
      v-show="media.show"
      :image="media.image"
      :video="media.video"
      :caption="media.caption"
      @close="media.show = false"
      @next="onNextImage"
      @previous="onPreviousImage"
    />
  </div>
</template>

<script>
import products from '@/assets/data/products';
import MediaPlayer from '@/components/MediaPlayer';

export default {
  name: 'ProductDetailView',
  props: ['slug'],
  components: {
    MediaPlayer,
  },
  data() {
    return {
      title: '',
      description: '',
      root: '',
      overview: '',
      activeTab: 'overview',
      images: [],
      videos: [],
      media: {
        show: false,
        currentIndex: 0,
        image: null,
        video: null,
        caption: null,
      },
    };
  },
  mounted() {
    const product = products.find((product) => product.slug === this.slug);

    this.title = product.title;
    this.description = product.description;
    this.root = product.root;
    this.overview = product.overview;
    this.images = product.images;
    this.videos = product.videos;
  },
  methods: {
    showMedia(filename, index) {
      let image;
      let video;
      let caption;

      if (/\.(mp4|mov|mpe*g|avi)/.exec(filename)) {
        video = `videos/${filename}`;
        caption = this.videos[index].title;
      } else {
        image = `images/galleries/${filename.replace(/^\.\//, '')}`;
      }

      this.media.video = video;
      this.media.image = image;
      this.media.currentIndex = index;
      this.media.caption = caption;
      this.media.show = true;
    },
    onNextImage() {
      const index =
        this.images.length === this.media.currentIndex + 1
          ? 0
          : this.media.currentIndex + 1;

      this.showMedia(this.images[index], index);
    },
    onPreviousImage() {
      const index =
        this.media.currentIndex === 0
          ? this.images.length - 1
          : this.media.currentIndex - 1;

      this.showMedia(this.images[index], index);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  margin-top: 2%;
  margin-bottom: 6%;
  @include breakpoint('both') {
    margin-top: 0;
    margin-bottom: 4%;
  }
}
.product-detail {
  padding: 0;
  flex-direction: row;
  .product-detail__content {
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 6%;
    @include breakpoint('both') {
      padding-bottom: 4%;
    }
    display: flex;
    width: 50%;
    box-sizing: border-box;
    flex-direction: column;
  }
  .product-detail__image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  .product-detail__tabs {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 4%;
    @include breakpoint('outside') {
      margin-bottom: 0;
    }
  }
}
.tabs {
  @include convert(width, 120, vw, $minWidth);
  @include breakpoint('both') {
    @include convert(width, 120, vh);
  }
  > a {
    height: 8vw;
    display: flex;
    background: $bg;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
    @include transition(opacity, 0.3s);
    @include breakpoint('both') {
      @include convert(height, 120, vh);
    }
    .icon {
      fill: $icon;
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.active {
      opacity: 1;
    }
    &.previous {
      background-color: $icon;
      opacity: 1;
      .icon {
        fill: $bg;
        height: 26%;
      }
    }
  }
}
.tab-contents {
  color: $body;
  box-sizing: border-box;
  width: calc(100% - #{((120 / $maxWidth) * 100) + vw});
  background: $bg;
  height: 100%;
  position: relative;
  @include transition(opacity, 0.3s);
  @include breakpoint('both') {
    width: calc(100% - #{((120 / $height) * 100) + vh});
  }
  > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  p {
    margin: 0;
    white-space: pre-line;
    font-size: 80%;
    line-height: 1.4;
  }
  .tab-content__inner {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    > div {
      position: absolute;
      height: 100%;
      overflow-y: scroll;
    }
  }
  .tab-content {
    padding: 6%;
    box-sizing: border-box;
    > h3 {
      color: white;
      margin-top: 0;
      margin-bottom: 6%;
      padding-bottom: 6%;
      border-bottom: 1px solid rgba(white, 0.1);
      font-weight: normal;
      font-size: 130%;
    }
    &.images .tab-content__inner {
      > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        column-gap: 2%;
        row-gap: 2%;
      }
      img {
        cursor: pointer;
        width: 100%;
        height: auto;
      }
    }
    &.videos .tab-content__inner {
      width: 100%;
      .video {
        display: flex;
        flex-direction: row;
        background: lighten($bg, 5%);
        padding: 2%;
        align-items: center;
        margin-bottom: 2%;
        h3 {
          margin: 0;
          color: white;
          font-weight: normal;
        }
        .thumbnail {
          width: 20%;
          line-height: 0;
          margin-right: 1em;
          position: relative;
          @include breakpoint('both') {
            width: 15%;
          }
          .icon {
            fill: white;
            width: 30%;
            @include center;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
