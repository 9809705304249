import { createRouter, createWebHashHistory } from 'vue-router';

import HomeView from '@/views/HomeView';
import ExtrusionDiesView from '@/views/ExtrusionDiesView';
import FeedblocksView from '@/views/FeedblocksView';
import AncillaryView from '@/views/AncillaryView';
import ProductDetailView from '@/views/ProductDetailView';
import CompanyView from '@/views/CompanyView';
import CompanyHistoryView from '@/views/CompanyHistoryView';
import CompanyLocationsView from '@/views/CompanyLocationsView';
import CompanyLabView from '@/views/CompanyLabView';
import CompanyAwardsView from '@/views/CompanyAwardsView';
import CompanyFAQView from '@/views/CompanyFAQView';

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      home: HomeView,
    },
  },
  {
    path: '/extrusion-dies',
    name: 'extrusion-dies',
    component: ExtrusionDiesView,
  },
  {
    path: '/extrusion-dies/:slug',
    name: 'extrusion-dies-detail',
    component: ProductDetailView,
    props: true,
  },
  {
    path: '/feedblocks',
    name: 'feedblocks',
    component: FeedblocksView,
  },
  {
    path: '/feedblocks/:slug',
    name: 'feedblocks-detail',
    component: ProductDetailView,
    props: true,
  },
  {
    path: '/ancillary',
    name: 'ancillary',
    component: AncillaryView,
  },
  {
    path: '/ancillary/:slug',
    name: 'ancillary-detail',
    component: ProductDetailView,
    props: true,
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyView,
  },
  {
    path: '/company/history',
    name: 'company-history',
    component: CompanyHistoryView,
  },
  {
    path: '/company/locations',
    name: 'company-locations',
    component: CompanyLocationsView,
  },
  {
    path: '/company/lab',
    name: 'company-lab',
    component: CompanyLabView,
  },
  {
    path: '/company/awards',
    name: 'company-awards',
    component: CompanyAwardsView,
  },
  {
    path: '/company/faq',
    name: 'company-faq',
    component: CompanyFAQView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
