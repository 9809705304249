import { createApp } from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import SvgIcon from '@/components/SvgIcon';
import Vue3TouchEvents from 'vue3-touch-events';

createApp(App)
  .component('SvgIcon', SvgIcon)
  .mixin({
    methods: {
      escape(text) {
        return text.replace(/™/g, '<sup>&trade;</sup>');
      },
      chunk(arr, size) {
        let results = [];

        for (let i = 0; i < arr.length; i += size) {
          results.push(arr.slice(i, i + size));
        }

        return results;
      },
      async createObjectURL(file) {
        const response = await fetch(file);
        const blob = new Blob([await response.arrayBuffer()]);

        return URL.createObjectURL(blob);
      },
    },
  })
  .use(Vue3TouchEvents)
  .use(router)
  .mount('#app');
