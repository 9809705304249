export default [
  { title: '2014 Alicia Cloeren', description: 'Plastics News Rising Star' },
  {
    title: '2013 Alicia Cloeren',
    description: "Elected to the Society Plastics Industry's Equipment Council",
  },
  // {
  //   title: '2012 Olivier Catherine',
  //   description:
  //     'Elected to serve on the Society of Plastics Engineers Board of Directors',
  // },
  {
    title: '2011 Cloeren Incorporated',
    description: 'DuPont Award for Sustainable Packaging - Presented by DuPont',
  },
  {
    title: '2006 Peter Cloeren - Fellow Award',
    description: 'Presented by Society of Plastics Engineers',
  },
  {
    title: '2001 Peter Cloeren - Jack Barney Award',
    description:
      'Presented by Extrusion Division Society of Plastics Engineers',
  },
  {
    title: '2000 Peter Cloeren - PLACE Division Technical Award',
    description: 'Presented by TAPPI',
  },
  {
    title: '2000 Peter Cloeren - Samuel Zweig Prize',
    description: 'Presented by TAPPI',
  },
];
