<template>
  <div class="products ancillary">
    <ProductsGrid
      title="Ancillary"
      description="Tooling to improve your extrusion process."
      :columns="4"
      :rows="1"
    />
  </div>
</template>

<script>
import ProductsGrid from '@/components/ProductsGrid';

export default {
  name: 'AncillaryView',
  components: {
    ProductsGrid,
  },
};
</script>
