<template>
  <div class="page-title">
    <div>
      <h2 v-text="title" />
      <p v-text="description" />
    </div>
    <div class="navigation" v-if="products.length > 1">
      <a
        class="previous"
        :class="{ disabled: activeIndex === 0 }"
        @click.prevent="previous"
      >
        <SvgIcon name="chevron-left" />
      </a>
      <a
        class="next"
        :class="{ disabled: activeIndex === products.length - 1 }"
        @click.prevent="next"
      >
        <SvgIcon name="chevron-right" />
      </a>
    </div>
  </div>
  <div class="products-grid" :style="{ '--columns': columns }">
    <TransitionGroup name="fade">
      <div
        class="products-grid__page"
        v-show="activeIndex === index"
        v-for="(chunks, index) in products"
        :key="index"
        v-touch:swipe.right="onTouchRight"
        v-touch:swipe.left="onTouchLeft"
      >
        <router-link
          :to="{
            name: `${product.root}-detail`,
            params: { slug: product.slug },
          }"
          v-for="product in chunks"
          :key="product"
        >
          <h2 class="title" v-html="escape(product.title)" />
          <p class="summary" v-html="escape(product.summary)" />
        </router-link>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import products from '@/assets/data/products';

export default {
  name: 'ProductsGrid',
  props: ['title', 'description', 'columns', 'rows'],
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    products() {
      return this.chunk(
        products.filter((product) => product.root === this.$route.name),
        this.rows * this.columns
      );
    },
    style() {
      return {
        '--columns': this.columns,
      };
    },
  },
  methods: {
    next() {
      let index = this.activeIndex + 1;

      if (this.products[index]) {
        this.activeIndex = index;
      }
    },
    previous() {
      let index = this.activeIndex - 1;

      if (this.products[index]) {
        this.activeIndex = index;
      }
    },
    onTouchLeft() {
      if (this.products.length > 1) {
        this.next();
      }
    },
    onTouchRight() {
      if (this.products.length > 1) {
        this.previous();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products-grid {
  position: relative;
  .products-grid__page {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-auto-rows: 1fr;
    gap: 4% 1.5%;
  }
  a {
    background: $bg;
    padding: 10%;
    color: white;
    .title {
      font-size: 180%;
    }
    .summary {
      color: $body;
      font-weight: bold;
      font-size: 95%;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }
}
</style>
