export default [
  {
    question: 'What is coextrusion?',
    answer:
      'Coextrusion is the process of forming multiple layers (plys) of differing polymers into a single membrane (film/sheet), wherein each polymer layer may contribute unique chemical, physical, or mechanical attributes to coextruded membrane. The multi-layer film thus exhibits enhanced characteristics that cannot otherwise be attained by a single ply membrane. For example, coextruded multi-layer films enable source reduction in packaging by creating thinner materials that match the strength of their predecessors. Coextrusion also remains the most economical means of creating products with multiple performance characteristics.',
  },
  {
    question: 'What materials can be used in coextrusion?',
    answer:
      'If it can be extruded, it can be coextruded. Typical materials include polyethylenes, polypropylenes, acetal, acrylics, polycarbonates, nylons, polystyrenes, polyesters, TPU’s, Plastomers and Elastomers, Ethylene Vinyl Alcohol Copolymer (EVOH), adhesives, polyvinyl chloride (PVC), etc.',
  },
  {
    question:
      'What is the difference between Fixed Geometry Feedblocks and Variable Geometry Feedblocks?',
    answer:
      'Cloeren Fixed Geometry Feedblocks are assembled for a specific coextruded structure. However, any adjustment requires a stop of the machine. Cloeren Variable Geometry Feedblocks, available from two layers to thirteen layers, allow for adjustability of the individual layer flow channel geometry while the machine is running, providing the ability for fine tuning of the process and faster product change overs.',
  },
  {
    question: 'What is the Black Box?',
    answer:
      'In the 1970s, with the advent of coextrusion in its early stages, the workings of the Feedblock coextrusion method where so unknown and mysterious to the user that the Feedblock was referred to as a "Black Box". When executives at Gulf Oil Corporation (now Chevron Phillips) approached Peter Cloeren Sr. to discuss a wish list for a new coextrusion apparatus, their desire was a device that could deliver one-, two- or three-layer coextruded structures from one, two or three extruders. They wanted the ability to change the layer sequence relatively quickly (without disconnecting feed pipes) and desired adjustable metering of the converging flow viscosities.  He returned just days later with a concept sketch of the first Cloeren Feedblock — his "Black Box." Gulf immediately purchased the first Cloeren Feedblock, and the rest is history.',
  },
  {
    question: 'What are the capabilities of Cloeren Feedblocks?',
    answer:
      'Our feedblocks handle a wide range of polymers, viscosities, rates and structures, from two layers to over a thousand layers, with industry leading performance and individual layer thickness tolerances.',
  },
  {
    question: 'What are extrusion dies?',
    answer:
      'The extrusion die forms the extrudate to a defined width and controlled thickness. The die ensures a uniform distribution of the polymer flow stream(s) across the width of the web being produced with it. ',
  },
  {
    question:
      'What is the difference between extrusion dies and web coating dies?',
    answer:
      'Extrusion dies deal with relatively high viscosity thermoplastics, at elevated temperatures, while web (or fluid) coating dies are used for processing relatively low viscosity fluids at relatively low or room temperature for applications such as pressure sensitive adhesives, hot melt adhesives, solvent or aqueous solution or emulsions. Web coating methods include proximity coating, contact coating and curtain coating. Also, while all thermoplastics are processed under laminar flow conditions, some web coating processes exceed laminar flow parameters.',
  },
  {
    question: 'Multi-manifold dies vs. feedblock?',
    answer:
      'The Feedblock method forms the multi-layer composite immediately upstream of the die and then the multi-layer flow stream is passed through the die where the flow stream is formed to a given width and thickness. By contrast, with a multi-manifold die, each polymer flow stream is formed into a layer within respective flow channels within the extrusion die. For example, a three-layer multi-manifold die would comprise three individual flow channels within one die body. By far, the Feedblock process is the most robust and common method used in the coextrusion process.',
  },
];
