<template>
  <div class="products feedblocks">
    <ProductsGrid
      title="Feedblocks"
      description="Our extrusion feedblocks produce films ranging from two layers to more
          than 1,000 layers."
      :columns="4"
      :rows="1"
    />
  </div>
</template>

<script>
import ProductsGrid from '@/components/ProductsGrid';

export default {
  name: 'FeedblocksView',
  components: {
    ProductsGrid,
  },
};
</script>
